import React, { useEffect } from "react";
import { navigate } from "gatsby-plugin-intl";

import * as constants from "../../app.constant";
import AppLayout from "../../components/shared/AppLayout";
import banner from "../../assets/images/desktop/banner.jpg";
import qrCode from "../../assets/images/desktop/qr-code-placeholder.jpg";

const PageQrScan = () => {

    useEffect(() => {
        localStorage.clear();
        navigate(constants.ROUTES.PURCHASE);
    }, []);

    return (
        <AppLayout hasHeader={true} hasHeaderLogo={true} isBackBtn={false} hasFooter={true}>
            <div className="page-qr-scan">

                <div className="qr-scan">

                    <div className="home-banner">
                        <div className="container">
                            <img className="img-fluid" src={banner} width="100%" alt="" />
                        </div>
                    </div>

                    <div className="instruction">
                        <div className="container">
                            <div className="instruction__inner">
                                <div className="instruction__box instruction__box--text">
                                    <p>Scan the QR code on the Galaxy device that you would like to protect to view this offer.</p>
                                </div>
                                <div className="instruction__box instruction__box--qr-code">
                                    <img className="img-fluid" src={qrCode} width="120" height="120" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </AppLayout>
    );
};

export default PageQrScan;
